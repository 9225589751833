import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

// import the component
import Mailchimp from 'react-mailchimp-form'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="container">
      <div className="intro">
        <div className="row">
          <div className="intro-col">
            <p className="intro-text">We're creating a platform that instantly connects UX professionals with those that need their services: <span className="green-underline">prototyping</span>, <span className="blue-underline">strategy</span>, <span className="yellow-underline">sprints</span>, <span className="purple-underline">mentorship</span>, & more...</p>
            <p className="form-label">Enter your email to stay informed</p>
            <Mailchimp
              action='https://unlimitedloop.us10.list-manage.com/subscribe/post?u=2762cf4f43d5b73a9c05eefa3&amp;id=968fbc0c33'
              fields={[
                {
                  name: 'EMAIL',
                  placeholder: 'Email',
                  type: 'email',
                  required: true,
                }
              ]}
              messages = {
                {
                  sending: "Sending...",
                  success: "Thank you for subscribing!",
                  error: "An unexpected internal error has occurred.",
                  empty: "Please enter a valid email address.",
                  duplicate: "This email address has already been used to subscribe.",
                  button: "Subscribe"
                }
              }
              // Personalized class
              className='email-form'
              />
          </div>
        </div>
      </div>
    </section>
    <section className="container">
      <div className="block">
        <div className="row">
          <div className="block-col">
            <h3 className="block-heading">Who we are</h3>
            <p className="block-description">
             We are a team of two, seasoned designers building products and technology that helps creatives connect with each other and with people looking for their services and skills.
            </p>
          </div>
        </div>
      </div>
    </section>
 </Layout>
)

export default IndexPage